/* eslint-disable no-console,no-undef, camelcase */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {Layout, Header, BuddyTab, NoData, AddUserCard, HeaderTitle, ButtonHris, Wrapper, StyledModal, PopupLine} from './style';
import { ImageUrl } from '../../utils/constants';
import { toast } from "react-toastify";
import { BASE_URL } from "../../utils/config";
import { fetchApi } from "../../utils/methods";
import { GetApiDeck, GetCurrentIntegrations, UpdateAutoSyncDays  } from "../../redux/constants/apiConstants";
import { useVault } from "@apideck/vault-react";
import HrisCardComponent from './hrisCard';
import Waiting from '../Waiting';
class HrisMain extends Component {
  constructor(props) {
    super();
    this.state = {
      showAdd: true,
      apiDeckConfig: null,
      integrations: [],
      integrationComponentRendered: false,
      companyId: props.companyInfo?.id,
      showSyncPopup: false,
      syncDay:7,
      postSyncResponse: null,
      showLoader: true
    };
  }

  componentDidMount() {
    this.getCurrentIntegrationsFromVault();
  }

  syncDays = [
    {
      id: 1,
      name: 7
    },
    {
      id: 2,
      name: 14
    },
    {
      id: 3,
      name: 28
    }
  ]

  hideAddCard = () =>{
    this.setState({
      showAdd: false
    })
  }

  getApiDeck = async () => {
    const companyId = this.state.companyId; 
    try {
      const res = await fetchApi(`${GetApiDeck}/${companyId}/${'new_session'}`, "GET");
      if (res.message) {
        // toast.error(res.message);
      } else {
        this.setState({
          apiDeckConfig: res.data,
          
        },()=>
        {
          this.manageIntegrations();
        });
      }
    } catch (error) {
      // toast.error(error.message);
    }
  };
  
  manageIntegrations = () => {
    const { open } = useVault();
    this.state.apiDeckConfig &&
      this.state.apiDeckConfig.session_token &&
      open({ token: this.state.apiDeckConfig.session_token,onConnectionDelete:()=>{    this.getCurrentIntegrationsFromVault()}});
  };

  getCurrentIntegrationsFromVault = async () => {
    const companyId = this.state.companyId; 
    try {
      const response = await fetchApi(`${GetCurrentIntegrations}/${companyId}/${'new_vault'}`, "GET");
      this.setState({
        showLoader: false
      })
      if (response.message) {
        toast.error(res.message);
      }
      else {
        const LogoData = response?.data
        const enabledData = LogoData?.filter((item) => item?.enabled);
        const connectedPlatform = enabledData?.slice(0, 2);
        this.setState({ integrations: connectedPlatform,integrationComponentRendered:true, });
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  sendReminderApiCall = async () => {
    const AuthToken = localStorage.getItem("AUTH_TOKEN");
    let payload = {
      company_id: this.state.companyId,
      days: this.state.syncDay
    };
    let url = BASE_URL + UpdateAutoSyncDays;
    await fetch(url, {
      method: "POST",
      headers: {
        AUTHTOKEN: AuthToken,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((res) => {
        res
          .json()
          .then((data) => {
            if (data.data.success_count) {
              this.setState({ showSyncPopup: false} );
            } else {
              this.setState({ showSyncPopup: false });
            }
          })
          .catch((error) => {
            toast.error(error.message);
          });
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  onCheckboxChange = (id) => {
    const { syncDay } = this.state;
    if(syncDay==id){
      this.setState({
        syncDay: null
      })
    }
    else{
      this.setState({
        syncDay: id
      })}
  };

  syncPopup = () => {
    const { showSyncPopup, syncDay } = this.state;
    return(
      <StyledModal show={showSyncPopup} width={"400px"}>
        <div className='header'>
          <div className='text'>Auto Sync</div>
          <div className='iconCross' onClick={()=>this.showSyncPopup()}>
            <img src={ImageUrl+"/event-section/cross.png"}/>
          </div>
        </div>
        <div className='body'>
          {this.syncDays.map((data,index)=>(
            <PopupLine key={index}>
              <div className='checkmark' onClick={()=>this.onCheckboxChange(data.name)}>
                {syncDay===data.name&&<img src={ImageUrl+"/ChallengeDetailsScreen/updatedCheckmark.svg"}/>}
              </div>
              <div className='text'>{data.name}</div>
            </PopupLine>
          ))}
          <div className='buttonWrapper'>
            <ButtonHris disable={!syncDay} onClick={!syncDay?null: ()=>this.sendReminderApiCall()}>
              Save Changes
            </ButtonHris>
          </div>
        </div>
      </StyledModal>
    )
  }

  showSyncPopup = () =>{
    this.setState({
      showSyncPopup: !this.state.showSyncPopup,
    })
  }

  render() {
    const { showAdd, integrations, showLoader } = this.state;
    if(showLoader){
      return(<Waiting/>)
    }
    return (
      <Layout>
        <div className='main'>
          <Header>
            <BuddyTab active>Integrations</BuddyTab>
            <div className='add' onClick={()=>this.getApiDeck()}>
              < img src={ImageUrl+"/Hris-image/add.svg"}/>
            </div>
          </Header>
          {this.props.companyBranding?.company_branding?.show_hris ? <>
            {showAdd&&!integrations.length>0&&<AddUserCard>
              <div className='hand'>
                <div className='image'>
                  < img src={ImageUrl+"/Hris-image/hand.png"}/>
                </div>
              </div>
              <div className='adduser'>
                <div className='subtext'>
                  Click here to integrate HRIS software
                  of your company for quick access to
                  Invite all your users in one click.
                </div>
                <div className='skip'onClick={()=>this.hideAddCard()}>Skip</div>
              </div>
            </AddUserCard>}
            {
              integrations.length===0?
                <NoData margin={showAdd?"-145px":"40px"}>
                  <div className='image'>
                    <img src={ImageUrl+"/"+"Hris-image/no-data.png"}/>
                  </div>
                  <div className='text'>
                    Want to save time? Integrate your HRIS Platform to automate new hire invites.<br/>
                    Also, we’ll remove termed employees to reduce your seats. Saving you money!
                  </div>
                </NoData>:
                <Wrapper>
                  <HeaderTitle>
                    <div className='text'>HRIS Tools</div>
                    <div className='line'></div>
                  </HeaderTitle>
                  {integrations.length>0&& integrations.map((data,index)=>(
                    data.enabled&&
                    <HrisCardComponent data={data} key={index} showSyncPopup={this.showSyncPopup}/>
                  ))}
                  {this.syncPopup()}
                </Wrapper>}
          </>:null}
        </div>
      </Layout>
    )
  }
}

HrisMain.propTypes = {
  history: PropTypes.object.isRequired,
  companyInfo: PropTypes.object,
  companyBranding: PropTypes.object,
};

const mapStateToProps = (state) => ({
  companyBranding: state.wellnessDashboard.companyBrandingDetails,
})

export default connect(null, mapStateToProps)(HrisMain);
